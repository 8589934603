// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.not-found-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.not-found-container1 {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.not-found-text1 {
  color: rgb(38, 38, 38);
  font-size: 252px;
  margin-top: -20px;
  font-weight: 900;
  margin-bottom: -20px;
  letter-spacing: -20px;
}
.not-found-container2 {
  width: 421px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.not-found-text2 {
  text-align: center;
  font-weight: 400;
}
`, "",{"version":3,"sources":["webpack://./src/views/not-found.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,cAAc;EACd,iBAAiB;EACjB,mBAAmB;EACnB,sBAAsB;EACtB,uBAAuB;AACzB;AACA;EACE,aAAa;EACb,kBAAkB;EAClB,mBAAmB;EACnB,sBAAsB;EACtB,uBAAuB;AACzB;AACA;EACE,sBAAsB;EACtB,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;EAChB,oBAAoB;EACpB,qBAAqB;AACvB;AACA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,uBAAuB;AACzB;AACA;EACE,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":[".not-found-container {\n  width: 100%;\n  display: flex;\n  overflow: auto;\n  min-height: 100vh;\n  align-items: center;\n  flex-direction: column;\n  justify-content: center;\n}\n.not-found-container1 {\n  display: flex;\n  position: relative;\n  align-items: center;\n  flex-direction: column;\n  justify-content: center;\n}\n.not-found-text1 {\n  color: rgb(38, 38, 38);\n  font-size: 252px;\n  margin-top: -20px;\n  font-weight: 900;\n  margin-bottom: -20px;\n  letter-spacing: -20px;\n}\n.not-found-container2 {\n  width: 421px;\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  justify-content: center;\n}\n.not-found-text2 {\n  text-align: center;\n  font-weight: 400;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
